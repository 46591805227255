@font-face {
font-family: '__akkurat_a1ee5f';
src: url(/_next/static/media/72e742d2cedcc9ec-s.p.ttf) format('truetype');
font-display: swap;
font-weight: 300;
font-style: normal;
}

@font-face {
font-family: '__akkurat_a1ee5f';
src: url(/_next/static/media/1c549ef65762b17e-s.p.ttf) format('truetype');
font-display: swap;
font-weight: 400;
font-style: normal;
}

@font-face {
font-family: '__akkurat_a1ee5f';
src: url(/_next/static/media/d8975d63bd9a702e-s.p.ttf) format('truetype');
font-display: swap;
font-weight: 400;
font-style: italic;
}

@font-face {
font-family: '__akkurat_a1ee5f';
src: url(/_next/static/media/2112563a43f62aa6-s.p.otf) format('opentype');
font-display: swap;
font-weight: 700;
font-style: normal;
}

@font-face {
font-family: '__akkurat_a1ee5f';
src: url(/_next/static/media/9370745d1316cef2-s.p.ttf) format('truetype');
font-display: swap;
font-weight: 700;
font-style: normal;
}@font-face {font-family: '__akkurat_Fallback_a1ee5f';src: local("Arial");ascent-override: 94.13%;descent-override: 23.46%;line-gap-override: 2.69%;size-adjust: 104.01%
}.__className_a1ee5f {font-family: '__akkurat_a1ee5f', '__akkurat_Fallback_a1ee5f', ui-sans-serif, system-ui, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji
}.__variable_a1ee5f {--font-akkurat: '__akkurat_a1ee5f', '__akkurat_Fallback_a1ee5f', ui-sans-serif, system-ui, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji
}

